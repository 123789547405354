const JobDetails = [
  {
    designation: "Academic Counsellor",
    company: "MyInception Tech",
    salaryRange: "8000 - 12000 (Month)",
    experience: "0-3 Years",
    location: " T.Nagar, Chennai",
    vacancy: 1,
    postedOn: new Date("2024-12-05"),
    jobDescription: [
      {
        title: "Academic Counsellor",
        industry: "NonIT",
        responsibilities: [
          "Should have rich experience in inside sales and driving admissions in software education space.",
          "Should convert student inquiries and leads into admissions.",
          "Should have hands-on experience in lead generation.",
          "Should have knowledge in sourcing candidates and building up a database.",
          "Actively track and follow up on potential leads.",
          "Should have proven records in target achievement.",
          "Should have good communication skills.",
        ],
        requirements: ["Any degree/BE (CSE,IT,ECE,EEE)(Highly prefered)."],
      },
    ],
  },
  {
    designation: "Hr Recruiter",
    company: "MyInception Tech",
    salaryRange: "Rs.40,000 ( Based on Experience)",
    experience: " 0-2 Years",
    location: "T.Nagar, chennai. ",
    vacancy: 1,
    postedOn: new Date("2024-12-05"),
    jobDescription: [
      {
        title: "Hr Recruiter",
        industry: "NonIT",
        responsibilities: [
          "Managing the end-to-end recruitment cycle",
          "Create & post job ads on various job portals & social media",
          "Assist in the mass hiring process by sourcing, screening, shortlisting, & interviewing candidates",
          "Assess and anticipate human resources-related needs",
          "Develop and nurture partnerships through human resources to bridge the divide between management and employees",
          "Ensure compliance with company policies and procedures during all stages of recruitment",
          "Collaborate with hiring managers to understand their requirements and provide guidance on interview processes",
          "Assist with Documentation and Onboarding processes",
          "Should have excellent communication",
          "Should have proven records in recruitment",
        ],
        requirements: [
          "Bachelor's degree or Master's Degree in  related field",
        ],
      },
    ],
  },
  // {
  //   designation: "Senior Accountant",
  //   company: "MyInception Tech",
  //   salaryRange: "Not Disclosed",
  //   experience: "5-7 Years",
  //   location: "Ekkatuthangal",
  //   vacancy: 1,
  //   postedOn: new Date("2024-12-05"),
  //   jobDescription: [
  //     {
  //       title: "Senior Accountant",
  //       industry: "NonIT",

  //       responsibilities: [
  //         "Financial Reporting: Prepare and analyze monthly, quarterly, and annual financial statements",
  //         "Budget Management: Develop and manage annual budgets, forecasts, and financial plans",
  //         "Fundraising: Lead efforts to raise funds for the company through various financial instruments, including bank guarantees, NFBCs, and loans",
  //         "Loan Management: Oversee the acquisition and management of secured and unsecured loans",
  //         "Compliance: Ensure compliance with all financial regulations and standards",
  //         "Auditing: Coordinate internal and external audits and implement audit recommendations",
  //         "Tax Planning: Develop and implement effective tax strategies to optimize the company's tax position",
  //       ],
  //       requirements: [
  //         "Bachelor's degree in Accounting, Finance, or a related field",
  //       ],
  //     },
  //   ],
  // },
  {
    designation: "Web Developer",
    company: "MyInception Tech",
    salaryRange: "Not Disclosed",
    experience: "3 Years",
    location: "T.nagar",
    vacancy: 1,
    postedOn: new Date("2024-12-05"),
    jobDescription: [
      {
        title: "Web Developer",
        industry: "IT",

        responsibilities: [
          "Website Development: Design, develop, and maintain websites and web applications using modern web technologies.",
          "Front-End Development: Create responsive and user-friendly interfaces using HTML, CSS, JavaScript, and front-end frameworks like React.js, Angular.js, or Vue.js. Ensure cross-browser compatibility and optimize web pages for performance.",
          "Back-End Development: Develop and maintain server-side logic, databases, and APIs. Implement back-end services and data storage solutions to support front-end applications.",
          "Integration and API Development: Integrate third-party APIs and services into web applications. Develop custom APIs to support various features and functionalities.",
          "Collaboration & Project Management: Work closely with designers, project managers or Business Analysts, and other department stakeholders to translate requirements into functional applications. Participate in code reviews, testing, and debugging processes to ensure the highest quality standards.",
          "Maintenance & Support: Monitor, troubleshoot, and resolve issues with existing web applications. Provide ongoing support and enhancements to existing websites and applications.",
          "Security & Compliance: Implement best practices for web security to protect against threats such as XSS, CSRF, SQL injection, and others. Ensure web applications comply with relevant standards and regulations.",
        ],
        requirements: ["BE/Diploma (Electronicas"],
      },
    ],
  },
  {
    designation: "On-premises DevOps Engineer",
    company: "MyInception Tech",
    salaryRange: "4.8 LPA to 6.5 LPA",
    experience: "4+ years",
    location: "T.Nagar,Chennai",
    vacancy: 1,
    postedOn: new Date("2024-12-05"),
    jobDescription: [
      {
        title: "On-premises DevOps Engineer",
        industry: "IT",

        responsibilities: [
          "CI/CD Pipeline Development: Build and maintain continuous integration and continuous deployment (CI/CD) pipelines using tools like Jenkins, GitHub Actions, Bitbucket Pipelines, or similar.",
          "Automation: Develop and manage infrastructure as code (IaC) using tools like Terraform, Ansible, or similar to automate environment provisioning and configuration management.",
          "Monitoring & Optimization: Implement monitoring solutions to ensure infrastructure performance and availability, troubleshoot issues, and optimize resources for cost and performance.",
          "Database Management: Administer on-premises databases, including installation, configuration, performance tuning, backup, recovery, and security. Ensure data integrity and high availability.",
          "Collaboration: Work closely with software development, QA, and operations teams to ensure smooth integration and deployment of applications.",
          "Security: Implement and manage security practices across the infrastructure, including vulnerability assessments, patch management, and compliance with company policies.",
          "Disaster Recovery: Develop and maintain backup and disaster recovery strategies for on-premises environments.",
          "Documentation: Create and maintain detailed documentation of infrastructure configurations, processes, and procedures.",
          "Configuration Management: Read, write, and troubleshoot JSON configuration files used in CI/CD processes, ensuring accuracy and efficiency in pipeline configurations.",
          "Experience with On-Premises Infrastructure",
        ],
        requirements: [
          "Bachelor’s degree in computer science, Engineering, or a related field",
        ],
      },
    ],
  },
  {
    designation: "Full Stack Developer(Senior Engineer)",
    company: "MyInception Tech",
    salaryRange: "Not Disclosed",
    experience: "4+ years",
    location: "T.Nagar, Chennai",
    vacancy: 1,
    postedOn: new Date("2024-12-05"),
    jobDescription: [
      {
        title: "Full Stack Developer(Senior Engineer)",
        industry: "IT",
        responsibilities: [
          "Full Stack Development: Design, develop, and maintain comprehensive applications using a range of technologies and frameworks, ensuring seamless integration within our on-premises infrastructure.",
          "Front-End Development: Develop intuitive and responsive user interfaces using modern front-end technologies such as React, Angular, or Vue.js.",
          "Back-End Development: Build and manage robust server-side applications and APIs using technologies such as Node.js, Python, Java, or .NET.",
          "Database Management: Design, implement, and maintain databases (both SQL and NoSQL) to ensure data integrity, security, and high performance.",
          "Infrastructure Integration: Ensure that applications are efficiently integrated with on-premises infrastructure, including servers, networking components, and storage solutions.",
          "Code Quality: Write clean, maintainable code and conduct thorough code reviews to ensure adherence to best practices and coding standards.",
          "Collaboration: Collaborate with product managers, UX/UI designers, and other developers to gather requirements, design solutions, and deliver high-quality software.",
          "Troubleshooting and Optimization: Diagnose and resolve issues, optimize performance, and implement enhancements to improve functionality and user experience.",
          "Documentation: Create and maintain comprehensive documentation for application code, system architecture, and development processes.",
          "Experience with On-Premises Infrastructure: Hands-on experience with on-premises server management, networking, and deployment.",
        ],
        requirements: [""],
      },
    ],
  },
  {
    designation: "ATE Test Engineer(All Levels)",
    company: "MyInception Tech",
    salaryRange: "Not Disclosed",
    experience: "1-5 years",
    location: "T.Nagar, Chennai",
    vacancy: 10,
    postedOn: new Date("2024-12-05"),
    jobDescription: [
      {
        title: "ATE Test Engineer(All Levels)",
        industry: "IT",
        responsibilities: [
          "Experience with at least 1 ATE test platform: Teradyne-uFlex, Advantest-93k, NI-STS.",
          "Experience with development of software such as IGXL, Smart Test, Labview, C# to test Integrated Circuits.",
          "Knowledge and hands-on experience with statistical analysis software packages and techniques, quality and reliability testing. Wafer fabrication fundamentals are a plus.",
          "Solid understanding of electronics engineering fundamentals, digital, analog, mixed-signal, power management, and RF circuit analysis techniques.",
          "Working knowledge of Power Management IC (PMIC) measurement techniques such as line/load regulation, PSRR, transition time, power efficiency, jitter, phase noise, etc.",
          "Good ASIC device level characterization skills. System level knowledge is a plus.",
          "Able to work comfortably with lab equipment like Oscilloscopes, Source Meters, Battery Emulators, Spectrum analyzers, etc.",
          "Able to work independently and with good initiative to overcome technical challenges.",
          "Strong verbal and written communication skills. Able to organize effectively and document work thoroughly while working with local and remote teams.",
          "Strong software skills (VB, Python, Perl, etc.) for writing and debugging test codes.",
          "Familiarity with the New Product Introduction process.",
          "Experience with the development of test hardware utilizing a team approach, reviewing schematics and layouts.",
          "Experience troubleshooting hardware and software to develop test solutions.",
          "Highly motivated, fast learner, team player.",
        ],
        requirements: ["Bachelor's degree in Relevant field"],
      },
    ],
  },
  {
    designation: "Graduate Apprentice / Engineer Trainee",
    company: "MyInception Tech",
    salaryRange: "Not Disclosed",
    experience: "Fresher",
    location: "T.Nagar, Chennai",
    vacancy: 50,
    postedOn: new Date("2024-12-05"),
    jobDescription: [
      {
        title: "ATE Test Engineer(All Levels)",
        industry: "NonIT",
        responsibilities: [
          "Should have good knowledge in production and quality maintenance.",
          "To inspect the quality of materials in the factory.",
          "Monitoring and maintaining products and services.",
          "Food and transport facilities provided.",
          "Attendance bonus and overtime pay provided.",
          "ESI and PF benefits.",
          "Preferred male candidate.",
        ],
        requirements: ["BE/Diploma"],
      },
    ],
  },
  {
    designation: "Product Development Engineer/Test (Scan, Bist, HSIO)",
    company: "MyInception Tech",
    salaryRange: "Not Disclosed",
    experience: "5+ years",
    location: "Singapore",
    vacancy: 6,
    postedOn: new Date("2024-12-05"),
    jobDescription: [
      {
        title: "Product Development Engineer/Test (Scan, Bist, HSIO)",
        industry: "IT",
        responsibilities: [
          "Collaborate with design, validation, and architecture teams for IP content coverage, test pattern development, validation, and debug",
          "Develop test program infrastructure incorporating latest Best-Known Methods",
          "Validate new product features, test conditions, test methodologies, and test contents during first silicon bring-up",
          "Perform silicon characterization across process, voltage, and temperature",
          "Perform test program correlation and release for high volume production",
          "Drive Key Performance Indicators such as test time reduction, yield debug, and quality improvement across products",
          "Support qualification activities and customer return debug",
        ],
        requirements: [
          "More than 5 years of experience in semiconductor product development",
          "Experience with ATE test program and pattern development – Advantest 93K, a must",
          "ATPG scan, BIST or HSIO pattern bring up experience",
          "Proficient in software programming and scripting languages (C, C++, Java, Ruby, Perl, Python)",
          "Knowledgeable in version control tools (Git, SVN, CVS)",
          "Knowledgeable in statistical analysis",
          "Proficient in Windows, Unix, and Linux operating systems",
          "Strong written and verbal communication skills",
        ],
      },
    ],
  },
  {
    designation: "Product Development Engineer",
    company: "MyInception Tech",
    salaryRange: "Not Disclosed",
    experience: "7+ years",
    location: "Singapore",
    vacancy: 6,
    postedOn: new Date("2024-12-05"),
    jobDescription: [
      {
        title: "Product Development Engineer",
        industry: "IT",
        responsibilities: [
          "Collaborate with design, validation, and architecture teams for IP content coverage, test pattern development, validation, and debug",
          "Develop test program infrastructure incorporating latest Best-Known Methods",
          "Validate new product features, test conditions, test methodologies, and test contents during first silicon bring-up",
          "Perform silicon characterization across process, voltage, and temperature",
          "Perform test program correlation and release for high volume production",
          "Drive Key Performance Indicators such as test time reduction, yield debug, and quality improvement across products",
          "Support qualification activities and customer return debug",
        ],
        requirements: [
          "7+ years of relevant experience in the Semiconductor industry",
          "Experience with data extraction, manipulation and visualization, using Statistical tools such as correlation, predictive modeling, contrast analysis, sample testing, Machine Learning etc.",
          "Wafer and FT/SLT test methodology and equipment knowledge",
          "Proficient understanding of Design of Experiments, Failure Analysis and RMA analysis",
          "Experience in applied Solid State Physics, Computer Architecture, Digital or Analog Design",
          "Experience with Python for data analysis, SQL for data extraction from databases, scripting languages (such as O10, O10++M10, Java, Ruby, Perl, Python)",
          "Strong proficiency in Excel, JMP, Yield Explorer, SAS, PowerBI or other EDA packages",
          "Ability to manage multiple projects and meet tight deadlines",
          "Proficient in Windows and Unix based operating systems",
          "Strong written and verbal communication skills",
        ],
      },
    ],
  },
  {
    designation: "Test Engineer – RF",
    company: "MyInception Tech",
    salaryRange: "Not Disclosed",
    experience: "3+ years",
    location: "Singapore",
    vacancy: 5,
    postedOn: new Date("2024-12-05"),
    jobDescription: [
      {
        title: "Test Engineer – RF",
        industry: "IT",
        responsibilities: [
          "Possess strong product knowledge on RF transceiver or Analog devices",
          "Work with cross functional team to develop the characterization and verification plan for the RF - Automotive devices",
          "Experience handling ATE and Bench test solutions for development and characterization",
          "Good debugging skill on test HW and software",
          "Experience to perform statistical data analysis, guard band calculation and correlations",
          "Ability to perform DOE testing, test specifications and drive solutions",
          "Experience in yield improvement and test time management to meet the goals without compromising production quality of high volume product",
          "Excellent communication and documentation skills to work with internal and external stakeholders",
          "Teradyne Ultra Flex and LabVIEW experience is preferred, Advantest 93K experience is a plus",
          "Knowledge of RF system development, automotive standard is a plus",
          "Working knowledge of VBA, Perl, Python programming is a plus",
        ],
        requirements: [
          "University Engineering Degree in Electrical / Electronic discipline with a basic understanding of electronics engineering fundamentals and circuit analysis",
          "3 plus years of experience in the Semiconductor/RF domain as a Test / Product / Validation Engineer with good knowledge on Semiconductor manufacturing process",
          "Knowledge on Tester Architectures, Test Resource usage and application of appropriate Test methodologies for Automated Test Equipment (ATE) environment",
          "Test Equipment programming & product debugging skills in ATE like Advantest 93K/ T2K, Teradyne FLEX/ETS Tester platforms, etc.",
          "Visual C++ and GUI based Software Programming capabilities with exposure to Unix / Linux environment",
          "Familiarity with the various Digital, SOC, Analog, Power Management and Mixed Signal Products and the testability of the electrical parameters in Wafer Sort & Final test",
          "Debug capabilities using ATE Measurement Analysis & Statistical Analysis Tools / Techniques",
          "Familiar with the use of all common electronic bench equipment like oscilloscope, spectrum analyzer, logic analyzer, network analyzer, etc.",
          "Strong verbal and written communications skills with internal team members and Customer teams",
          "Able to work independently and with good initiative to overcome technical challenges",
        ],
      },
    ],
  },
  {
    designation: "customer support Executive",
    company: "MyInception Tech",
    salaryRange: "Not Disclosed",
    experience: "1 to 3 years",
    location: "T nagar, Chennai",
    vacancy: 5,
    postedOn: new Date("2024-12-05"),
    jobDescription: [
      {
        title: "Telesales Executive",
        industry: "IT",
        responsibilities: [
          "Rich experience in inside sales and driving sales in car insurance and warranty",
          "Ability to convert leads into customers",
          "Hands-on experience in lead generation",
          "Knowledge in convincing leads and converting them into customers",
          "Actively tracking and following up on potential leads",
          "Proven records in target achievement",
          "Good communication skills",
          "Fluency in Hindi is an added advantage",
        ],
        requirements: ["Any degree"],
      },
    ],
  },
  {
    designation: "Regional Manager",
    company: "MyInception Tech",
    salaryRange: "Not Disclosed",
    experience: "7 to 10 years",
    location: "Telungana, Karnataka, Mumbai",
    vacancy: 5,
    postedOn: new Date("2024-12-05"),
    jobDescription: [
      {
        title: "Regional Manager",
        industry: "NonIT",
        responsibilities: [
          "In charge of overall region related to projects (Leadership)",
          "Review of project deliverables",
          "Planning and control",
          "Collection of data and monitoring",
          "Billing and revenue tracking",
          "Monitoring the job and supporting the team on issues",
          "Providing support functions",
          "Ensuring statutory and legal compliances",
        ],
        requirements: ["BE -Elecrical/Mechanical/Civil"],
      },
    ],
  },
  {
    designation: "Assistant Manager",
    company: "MyInception Tech",
    salaryRange: "Not Disclosed",
    experience: "5 to 8 years",
    location: "Tamilnadu, Mumbai",
    vacancy: 5,
    postedOn: new Date("2024-12-05"),
    jobDescription: [
      {
        title: "Assistant Manager",
        industry: "NonIT",
        responsibilities: [
          "In charge of overall region related to projects (Leadership)",
          "Review of project deliverables",
          "Planning and control",
          "Collection of data and monitoring",
          "Billing and revenue tracking",
          "Monitoring the job and supporting the team on issues",
          "Providing support functions",
          "Ensuring statutory and legal compliances",
        ],
        requirements: ["BE -Elecrical/Mechanical/Civil"],
      },
    ],
  },
  {
    designation: "Senior Engineer",
    company: "MyInception Tech",
    salaryRange: "Not Disclosed",
    experience: "2 to 5 years",
    location: "Tamilnadu.",
    vacancy: 5,
    postedOn: new Date("2024-12-05"),
    jobDescription: [
      {
        title: "Senior Engineer",
        industry: "NonIT",
        responsibilities: [
          "Work at site locations, attending meetings, review of works",
          "Coordination with design team on the changes, as-built preparations",
          "Review of drawings and update on the changes",
          "Coordination with client for materials and approvals",
          "Monitoring site activities",
          "Preparation of bills (Supply & Installation), submission of TDS, Preparation of MIR, JMS",
          "Submission of draft invoices to client",
          "Monitoring site movement",
          "Control measures on expenses incurred",
          "Planning material requirements",
          "Knowledge on statutory and legal compliances",
          "Interaction with clients, consultants, and attending meetings",
        ],
        requirements: ["BE -Elecrical/Mechanical/Civil"],
      },
    ],
  },
  {
    designation: "Supervisor",
    company: "MyInception Tech",
    salaryRange: "Not Disclosed",
    experience: "3 ot 5 years",
    location: "Telungana, Pune, Karnataka.",
    vacancy: 5,
    postedOn: new Date("2024-12-05"),
    jobDescription: [
      {
        title: "Supervisor",
        industry: "NonIT",
        responsibilities: [
          "Work at site locations as per Engineer instructions",
          "Assigning works to team",
          "Review of drawings and update on the changes",
          "Providing site progress updates",
          "Monitoring site activities",
          "Preparation of checklist and getting certified from client",
          "Assisting Engineers for bill preparation",
        ],
        requirements: ["BE -Elecrical/Mechanical/Civil"],
      },
    ],
  },
  {
    designation: "Admin Executive",
    company: "MyInception Tech",
    salaryRange: "Not Disclosed",
    experience: "2 to 5 years",
    location: "Andhra Pradesh, Mumbai, Pune, Delhi, Karnataka, Tamilnadu.",
    vacancy: 5,
    postedOn: new Date("2024-12-05"),
    jobDescription: [
      {
        title: "Admin Executive",
        industry: "NonIT",
        responsibilities: [
          "Responsible for billing coordination and follow-up",
          "Responsible for stores and material management",
          "Manpower and attendance management",
          "Responsible for HR life cycle in the region",
          "Acting as point of contact in terms of Admin and HR"
        ],
        requirements: ["Any Degree"],
      },
    ],
  },
  {
    designation: "Business Development Manager",
    company: "MyInception Tech",
    salaryRange: "Not Disclosed",
    experience: "5 years",
    location: "Delhi, Mumbai, Chennai, Bangalore, Hyderbad, Kolkata.",
    vacancy: 5,
    postedOn: new Date("2024-12-05"),
    jobDescription: [
      {
        title: "Business Development Manager",
        industry: "NonIT",
        responsibilities: [
          "Arrange and conduct business meetings with prospective clients",
          "Promote the company’s products/services, addressing or anticipating clients' objectives",
          "Respond to inquiries and prepare detailed offers with design concept layouts for customers",
          "Expand the customer base by achieving order bookings and sales targets",
          "Engage in technical discussions with customers at their locations as necessary",
          "Manage the full sales cycle, from inquiry response and design approvals to price negotiations and final order booking",
          "Oversee dispatch planning and maintain clear communication with customers regarding delivery updates",
          "Address customer complaints promptly and coordinate with relevant teams for swift resolution",
          "Prepare and analyze customer feedback reports to drive continuous improvement"
        ],
        requirements: ["BE Electrical /Diploma Electrical "],
      },
    ],
  },
  {
    designation: "Sales Co ordinator",
    company: "MyInception Tech",
    salaryRange: "Not Disclosed",
    experience: "3 ot 4 years",
    location: "Andhra Pradesh, Mumbai, Pune, Delhi, Karnataka, Tamilnadu.",
    vacancy: 5,
    postedOn: new Date("2024-12-05"),
    jobDescription: [
      {
        title: "Sales Co ordinator",
        industry: "NonIT",
        responsibilities: [
          "Order processing through Tally ERP",
          "Cross checking the purchase order with sales order",
          "Coordinating with internal team for smooth process",
          "Maintaining the sales report, Tracking files and Material Movement Sheet on daily basis",
          "Responsible for payment collection",
          "Tracking the material dispatch till it reaches the end-user",
          "Sound knowledge in Excel formulas",
          "Knowledge in Tally ERP",
          "Good communication required in English and Hindi"
        ],
        requirements: ["Any Degree "],
      },
    ],
  },
];

export default JobDetails;
